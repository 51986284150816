import React, { useState, useEffect,useContext } from "react";
import { useNavigate } from "react-router-dom";
import { ClickSourceContext } from "../context/clickSourceContext";
import axios from "axios";
import Logo from "../components/Logo";
import navIcon from '../assets/images/nav.svg';
import ClipLoader from "react-spinners/ClipLoader";

const MintFeedback = () => {
  const sourceCtx = useContext(ClickSourceContext);
  const [mintSuccess, setMintSuccess] = useState(null);
  const [errorText, setErrorText] = useState('');
  const [imgUrl, setImgUrl] = useState('');
  const [isLoading,setLoading] = useState(true)
  const navigate = useNavigate();

  useEffect(() => {
  if(!sourceCtx.scannedAddress){
    navigate("/")
  }

  }, []);

  const goToNfts = () => {
    navigate({pathname: '/nfts', replace: true})
  }
  const goBack = () =>{
    navigate({pathname: '/', replace: true})
  }

  const goToScanner = () => {
    navigate({pathname: '/scanner', replace: true})
  }

  useEffect(() => {
    const Mint = async () =>{
      let PIN = sessionStorage.getItem("stationId");
      let userAddress = sourceCtx.scannedAddress;
      setLoading(true)
      await axios.post(process.env.REACT_APP_BACKEND_URL + "whitelistMint",{
        "PIN":PIN, "userAddress": userAddress
      }).then((response)=>{
        let respData = response.data;
        if(respData.error){
          var trimmedString = respData.error.substring(0, 63);
          setMintSuccess(false);
          setErrorText(trimmedString)
        }else{
          setImgUrl(respData.result.image)
          setMintSuccess(true)
        }
      }).catch(e=>
        {
          console.log(e, " error")
          setMintSuccess(false)
          setErrorText(e.message);
        // setCallError(true);
        // setErrorText(e.message)
      }
        )
        setLoading(false)
    }
    Mint();
  }, [])
  
  const navigateBack = () => {
    navigate('/dashboard');
  }

  return (
    <>
    
    {!isLoading ? 
    <div className={`mint-feedback-container  ${!mintSuccess ? "error-inner-texts" : ""}`}>
      <img src={navIcon} className="navback-icon" onClick={navigateBack}></img>
      <Logo/>
      <div className="mint-feedback-inner-texts">
        {mintSuccess === true ?(
          <>
            <p className="success-mint">SUCCESS!</p>
            <p className="mint-message">
              NFT minted!
            </p>
            <img style={{maxHeight:"250px"}} src={imgUrl}/>
          </>
        ) : (
          <>
            {mintSuccess !== null && 
            <div className="error-div">
            <p className="error-mint"> ERROR </p>
            <p className="mint-message">
             {errorText}
            </p>
            </div>
          }
          </>
        )}
      </div>
      <div className="mintpage-button-holder">
        {mintSuccess ? 
        <><button className="mintpage-button" onClick={goToNfts}>SHOW NFTS</button>
        <button className="mintpage-button" onClick={goToScanner}>MINT NFT</button>
        </> : 
        <>
        {errorText.includes("Address already minted") ? <button className="mintpage-button" onClick={goToNfts}>SHOW NFTS</button> : <button className="mintpage-button" onClick={goBack}>RETRY</button>}
        </>
      }
      </div>
     
    </div>
    :
    <div style={{position:"absolute", inset:0, margin:"auto", height:"fit-content"}}>
     <ClipLoader
     color={"rgba(176, 147, 91, 0.8)"}
     loading={isLoading}
     size={140}
     aria-label="Loading Spinner"
     data-testid="loader"
   /></div>
  }
   </>
 
  );
};

export default MintFeedback;
