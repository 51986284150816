import React, { useEffect, useRef, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import Logo from './Logo';
import axios from 'axios';

const PinCodeInput = () => {
  const inputs = document.querySelectorAll('input');
  const [isTouched, setIsTouched] = useState(false);
  const [inputValue, setInputValue] = useState(0);
  const [isValid, setIsValid] = useState(true);
  const [pinIsValid, setPinIsValid] = useState(true);
  const [errorText, setErrorText] = useState('');
  const [callError, setCallError] = useState(false);
  const [firstNum, setFirstNum] = useState(null);
  const [secondNum, setSecondNum] = useState(null);
  const [thirdNum, setThirdNum] = useState(null);
  const [fourthNum, setFourthNum] = useState(null);
  const [showWarningText, setShowWarningText] = useState(false);

  const secondInputRef = useRef(null);
  const thirdInputRef = useRef(null);
  const fourthInputRef = useRef(null);

  // const [pin,setPin] = useState(null);
  const navigate = useNavigate();

  useEffect(() => {
    if ((firstNum, secondNum, thirdNum, fourthNum)) {
      let pin = firstNum + '' + secondNum + '' + thirdNum + '' + fourthNum;
      const Login = async () => {
        await axios
        .post(process.env.REACT_APP_BACKEND_URL + 'login/',{"PIN":pin})
          .then((response) => {
            let respData = response.data;
            if (respData.error) {
              setPinIsValid(false);
              setShowWarningText(true);
              inputs.forEach((input) => {
                input.classList.add('incorrect-pin');
                input.addEventListener('click', () => {
                  inputs.forEach((input) => {
                    setShowWarningText(false);
                    input.value = '';
                    input.classList.remove('incorrect-pin');
                  });
                });
              });

              setFirstNum(null);
              setSecondNum(null);
              setThirdNum(null);
              setFourthNum(null);
            } else {
              window.sessionStorage.setItem('stationId', pin);
              getStationImg(pin);
            }
          })
          .catch((e) => {
            console.log(e, ' error');
            setCallError(true);
            setErrorText(e.message);
          });
      };
      Login();
      const getStationImg = async (pinCode) => {
        await axios
          .post(process.env.REACT_APP_BACKEND_URL + 'getStationInformation/',{"PIN":pinCode})
          .then((response)=>{
            if(response.data.result){
              sessionStorage.setItem("stationImage", response.data.result.image);
              sessionStorage.setItem("stationName", response.data.result.collectionName);
              goToDashBoard();
            }
          }
           
          )
          .catch((error) => console.log(error, ' station img error'));
      };
    }
  }, [firstNum, secondNum, thirdNum, fourthNum]);

  // useEffect(() => {
  //   if(!pinIsValid){
  //     inputs.forEach((input) => {
  //       input.classList.add("incorrect-pin");
  //       input.addEventListener("click", () => {
  //         inputs.forEach((input) => {
  //           input.value="";
  //           input.classList.remove("incorrect-pin");})
  //       });
  //     });

  //     setFirstNum(null);
  //     setSecondNum(null);
  //     setThirdNum(null);
  //     setFourthNum(null);
  //   }
  // }, [pinIsValid])

  const goToDashBoard = () => {
    navigate({ pathname: '/dashboard', replace: true });
  };

  return (
    <>
      <Logo />
      <form className="pin-form">
        <div className="pin-input">
          <p className="enter-pin">Enter PIN</p>
          <div className="inputs">
            <input
              type="text"
              inputMode="numeric"
              className="input-field"
              maxLength="1"
              onChange={(e) => {
                setFirstNum(e.target.value);
                secondInputRef.current.focus();
              }}
            ></input>
            <input
              type="text"
              inputMode="numeric"
              className="input-field"
              maxLength="1"
              ref={secondInputRef}
              onChange={(e) => {
                setSecondNum(e.target.value);
                thirdInputRef.current.focus();
              }}
            ></input>
            <input
              type="text"
              inputMode="numeric"
              className="input-field"
              maxLength="1"
              ref={thirdInputRef}
              onChange={(e) => {
                setThirdNum(e.target.value);
                fourthInputRef.current.focus();
              }}
            ></input>
            <input
              type="text"
              inputMode="numeric"
              className="input-field"
              maxLength="1"
              ref={fourthInputRef}
              onChange={(e) => setFourthNum(e.target.value)}
            ></input>
          </div>
        </div>
        {showWarningText && (
          <div className="warning-text">
            <p>Wrong PIN. Try again.</p>
          </div>
        )}
        {callError && (
          <>
            <div className="warning-text">
              <p>{errorText}</p>
            </div>
          </>
        )}
      </form>
    </>
  );
};

export default PinCodeInput;
