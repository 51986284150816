import React, { useState, useEffect, useContext } from "react";
import { useNavigate } from "react-router-dom";
import { ClickSourceContext } from "../context/clickSourceContext";
import dummy from "../assets/images/dummy-nft.png";
import Logo from "../components/Logo";
import logoutIcon from "../assets/images/logout.svg";
import ClipLoader from "react-spinners/ClipLoader";

const Dashboard = () => {
  const [stationNumber, setStationNumber] = useState(
    "Lobkowicz Castle Painting"
  );
  const [stationImage, setStationImage] = useState(null);
  const navigate = useNavigate();
  const sourceCtx = useContext(ClickSourceContext);

  useEffect(() => {
    window.scrollTo(0, 0);
    let stationID = window.sessionStorage.getItem("stationId");
    setStationImage(sessionStorage.getItem("stationImage"));
    setStationNumber(sessionStorage.getItem("stationName"));
    if (!stationID) {
      navigate("/");
    }
  }, []);

  const goToQRReader = (event) => {
    const source = event.target || event.srcElement;
    const sourceId = source.id;
    sourceCtx.setSourceId(sourceId);
    navigate({ pathname: "/scanner", replace: true });
  };

  const logOut = () => {
    sessionStorage.clear();
    navigate("/");
  };

  return (
    <div className="dashboard-container">
      <Logo />
      <div className="logout-btn" onClick={logOut}>
        <img src={logoutIcon} />
      </div>
      <div className="station-container">
      <div className="station-number">
        <p className="experience">EXPERIENCE</p>
        <p className="experience-name">{stationNumber}</p>
        {stationImage ? (
          <img src={stationImage} className="dashboard-img" />
        ) : (
          <ClipLoader
            color={"rgba(176, 147, 91, 0.8)"}
            // loading={isLoading}
            size={140}
            aria-label="Loading Spinner"
            data-testid="loader"
          />
        )}
      </div>
      <div className="buttons">
        <button className="dash-buttons" id="show" onClick={goToQRReader}>
          SHOW NFTS
        </button>
        <button className="dash-buttons" id="mint" onClick={goToQRReader}>
          MINT NFTS
        </button>
      </div>
      </div>
    </div>
  );
};

export default Dashboard;
