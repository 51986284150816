import React, { useState, useEffect,useContext } from "react";
import QrReader from "react-qr-reader";
import { useNavigate } from "react-router-dom";
import NFTs from "../views/NFTs";
import closeBtn from '../assets/images/close.png'
import { ClickSourceContext } from "../context/clickSourceContext";

const QrScanner = ({walletAddress}) => {
  const sourceCtx = useContext(ClickSourceContext);
  const [code, setCode] = useState(null);
  const [showDialog, setDiaglog] = useState(false);
  const [processing, setProcessing] = useState(false);
  const [precScan, setPrecScan] = useState("");
  const [selected, setSelected] = useState("environment");
  const [errorMessage, setErrorMessage] = useState(null);
  const navigate = useNavigate();

  const handleScan = (scanData) => {
    if (scanData && scanData) {
      walletAddress(scanData)
      setCode(scanData);
      sourceCtx.setScannedAddress(scanData)
    }
  };
  const handleError = (err) => {
    console.error(err);
  };

    const handleNavigate = () => {
    navigate("/dashboard");
  };

  if (code != null) {
    navigate("/nfts");
  }

  return (
    <>
      <div className="scanner-holder">
        <button className="qr-close-btn" onClick={handleNavigate}>
          <img src={closeBtn} />
        </button>
        {!showDialog && !processing && (
          <QrReader
            delay={500}
            onScan={handleScan}
            constraints={{
              facingMode: "environment",
            }}
            // style={{ width: "100%", heigth: "100%" }}
            className="qr-reader"
          />
        )}
      </div>

      {code && <NFTs address={code} />}
    </>
  );
};

export default QrScanner;
