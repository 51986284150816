import React from "react";
import Login from "../views/Login";
import Dashboard from "../views/Dashboard";
import NFTs from "../views/NFTs";
import Scanner from "../views/Scanner";
import MintFeedback from "../views/MintFeedback";
import NewScanner from "../views/NewScanner";

const routes = [
  {
    path: "/",
    element: <Login />,
    exact: true,
  },
  {
    path: "/dashboard",
    element: <Dashboard />,
    exact: true,
  },
  {
    path: "/nfts",
    element: <NFTs />,
    exact: true,
  },
  {
    path: "/scanner",
    element: <Scanner />,
    exact: true,
  },
  {
    path: "/mint",
    element: <MintFeedback />,
    exact: true,
  },
];

export default routes;
