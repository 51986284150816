import React, { useState, useEffect, useContext } from "react";
import { LobkowitzWallet } from "lobkowitz-wallet-component";
import QrScanner from "../components/QRScanner";
import { useNavigate } from "react-router-dom";
import { ClickSourceContext } from "../context/clickSourceContext";
import NFTs from "./NFTs";

const Scanner = () => {
  const [walletAddress, setWalletAddress] = useState(null);
  const navigate = useNavigate();
  const sourceCtx = useContext(ClickSourceContext);
  
  if (walletAddress !== null) {
     if (sourceCtx.sourceId === "mint") {
      navigate({ pathname: "/mint", replace: true });
    }
  }

  return (
    <>
        {
      !walletAddress ? <QrScanner walletAddress={(add)=>{setWalletAddress(add)}}/> :  <NFTs address={walletAddress}/>
    }
    </>
  );
};

export default Scanner;
