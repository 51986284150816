import React, {useEffect} from 'react';
import splashHouse from '../assets/images/house-of-lobkovicz.svg';
import splashLogo from  '../assets/images/logo-splash.svg';
import Logo from './Logo';

const SplashPage = () => {

    useEffect(() => {
        const logo = document.querySelector(".logo");
        logo.style.display = "none";
      }, [])

  return (
    <>
    <Logo/>
    <div className='splash-screen'>
    <div className='splash-screen-svgs'>
        <img src={splashHouse} className="splash-house"></img>
        <img src={splashLogo} className="splash-logo"></img>
    </div>
    </div>
    </>
  )
}

export default SplashPage