import { createContext, useState } from "react";

export const ClickSourceContext = createContext({
  sourceId: null,
  setSourceId: () => {},
  scannedAddress:null,
  setScannedAddress: () => {},
});

const ClickSourceContextProvider = (props) => {
  const [clickSourceId, setClickSourceId] = useState("");
  const [address, setScannedAddress] = useState("");
  const setSource = (id) => {
    setClickSourceId(id);
  };

  const setAddress = (addr) =>{
    setScannedAddress(addr)
  }
  return (
    <ClickSourceContext.Provider
      value={{ sourceId: clickSourceId, setSourceId: setSource, scannedAddress:address, setScannedAddress:setScannedAddress}}>
      {props.children}
    </ClickSourceContext.Provider>
  );
};

export default ClickSourceContextProvider;
