import React, { useEffect, useState } from "react";
import PinCodeInput from "../components/PinCodeInput";
import SplashPage from "../components/SplashPage";
import { useNavigate } from "react-router-dom";
const Login = () => {
  const [isLoaded, setIsLoaded] = useState(false);
  const navigate = useNavigate();
  useEffect(() => {
    let stationID = window.sessionStorage.getItem("stationId");
    if(stationID){
      navigate("/dashboard")
    }
    setTimeout(() => {
      setIsLoaded(true)
    }, 2000)
  }, []);

  return <>{isLoaded ? (<PinCodeInput />) : (<SplashPage />)}</>;
};

export default Login;
