import React, {useEffect,useContext, useState} from 'react';
import { LobkowitzWallet } from 'lobkowitz-wallet-component';
import { useNavigate } from "react-router-dom";
import { ClickSourceContext } from '../context/clickSourceContext';

//const walletAddress = '0xedc21400DafD1CBa357cBe99d56197c20Da51d37';

const NFTs = ({address}) => {
  const sourceCtx = useContext(ClickSourceContext);
  const [userAddress,setUserAddress] = useState(address)
  const navigate = useNavigate();

  useEffect(() => {
    if(sourceCtx.scannedAddress){
      setUserAddress(sourceCtx.scannedAddress);
    }
    if(address == undefined && sourceCtx.scannedAddress == ''  ){
      navigate("/")
    }
  }, [])

  const goBack = () => {
    navigate("/dashboard");
  };

  const goToScanner = () => {
    navigate("/scanner");
  }
  
  return (
    <>
 {
  userAddress !== undefined ? <LobkowitzWallet address={userAddress} navigateBack={goBack} errorNavigateBack={goBack} contractAddress={process.env.REACT_APP_CONTRACT_ADDRESS} rpc_url={process.env.REACT_APP_RPC_URL }/> : <p>loading..</p>}
    
   </>
  )
}

export default NFTs