import React, {useEffect} from "react";
import "./App.css";
import routes from "./routes/Routes";
import { Routes, Route, HashRouter, BrowserRouter } from "react-router-dom";
import ClickSourceContextProvider from "./context/clickSourceContext";
import background from "./assets/images/background.png";
import Logo from "./components/Logo";

function App() {
  return (
    <ClickSourceContextProvider>
      <BrowserRouter basename="/">
        {/* <div className="holder"> */}
        {/* <img src={background} className="main-image" id="main-image"></img> */}
        {/* <Logo/> */}
        <Routes>
          {routes.map(({ path, element, exact }) => (
            <Route key={path} path={path} exact={exact} element={element} />
          ))}
        </Routes>
        {/* </div> */}
      </BrowserRouter>
    </ClickSourceContextProvider>
  );
}

export default App;
